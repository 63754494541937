import {Component} from "@angular/core";
import {CommonModule} from "@angular/common";

@Component({
    selector: "ft-not-found",
    templateUrl: "./page-not-found.component.html",
    styleUrl: "./page-not-found.component.scss",
    imports: [CommonModule]
})
export class PageNotFoundComponent {

}