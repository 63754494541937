import { Routes } from '@angular/router';
import { AuthGuard, LoginGuard } from './auth';
import {PageNotFoundComponent} from "./shared/404/page-not-found.component";


export const appRoutes: Routes= [
	{ path: '', pathMatch: 'full', redirectTo: '/scheduler/schedule-manager' },
	{
		path: 'ft-queue',
		loadComponent: () => import('./shared/queue/queue.component').then(c => c.QueueComponent,),
	},
	{
		path: 'login',
		canActivate: [LoginGuard],
		loadComponent: () => import('./auth/login.component').then(c => c.LoginComponent),
	},
	{
		path: '',
		canActivate: [AuthGuard],
		loadComponent: () => import('./main.component').then(c => c.MainComponent),
		loadChildren: () => import('./main.routes').then(routes => routes.MAIN_ROUTES)
	},
	{
		path: 'external-viewer/:study',
		canActivate: [AuthGuard],
		loadComponent: () => import('./external-viewer/external-viewer.component').then(c => c.ExternalViewerComponent)
	},
	{
		path: 'doc-reader/:docId',
		canActivate: [AuthGuard],
		loadComponent: () => import('./shared/doc-reader/doc-reader.component').then(c => c.DocReaderComponent),
	},
	{ path: '**', component: PageNotFoundComponent }
];