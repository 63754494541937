import {ApplicationConfig, enableProdMode} from '@angular/core';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {provideRouter, withRouterConfig, withViewTransitions} from '@angular/router';
import {appRoutes} from './app.routes';

import {environment} from '../environments/environment';
import {
    provideSharedConfig,
    provideI18nConfig,
    provideJwtConfig,
    provideMomentConfig,
    provideMaterialConfig,
} from './config';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';


if (environment.production) {
    enableProdMode();
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideHttpClient(withInterceptorsFromDi()),
        provideI18nConfig(),
        provideJwtConfig(),
        provideSharedConfig(),
        provideMomentConfig(),
        provideMaterialConfig(),
        provideRouter(appRoutes,
            withViewTransitions(),
            withRouterConfig({paramsInheritanceStrategy: 'always'})),
        provideAnimationsAsync(),
    ],
};
